<template>
    <section class="">
        <div class="container w-full mb-15 lg:mb-24">
            <nuxt-link :to="bannerShow?.btn_link" class="block w-full">
                <div class="w-full h-full">
                    <NuxtImg
                        v-if="bannerShow?.image_bg"
                        :src="getThumbnail(bannerShow?.image_bg, 2000, true)"
                        width="2000"
                        sizes="sm:100vw md:50vw lg:2000px "
                        loading="lazy"
                        alt="Thumbail Event"
                        :placeholder="[50, 25, 75, 5]"
                        class="w-full h-full object-cover hidden lg:block"
                    />
                    <NuxtImg
                        v-if="bannerShow?.image_mobile"
                        :src="getThumbnail(bannerShow?.image_mobile, 2000, true)"
                        width="2000"
                        sizes="sm:100vw md:50vw lg:2000px "
                        loading="lazy"
                        alt="Thumbail Event"
                        :placeholder="[50, 25, 75, 5]"
                        class="w-full h-full object-cover lg:hidden"
                    />
                    <!-- <img src="/images/home/black-friday-header.png" alt="" class="w-full h-full object-cover" /> -->
                </div>
            </nuxt-link>
        </div>
    </section>
</template>

<script setup lang="ts">
const { getBanner } = useBanner()
const banners = await getBanner({ name: 'Event' })
const bannerShow = ref(
    banners && banners?.banners?.banner_items && banners?.banners?.banner_items.length > 0
        ? banners?.banners?.banner_items[1]
        : {}
)
</script>

<style></style>
