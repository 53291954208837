<template>
    <section v-if="data && data.length > 0">
        <div class="container relative my-15 lg:my-22">
            <Swiper
                @swiper="getSwiper"
                :modules="[SwiperNavigation]"
                :spaceBetween="50"
                :breakpoints="{
                    300: { slidesPerView: 2.5, spaceBetween: 20 },
                    400: { slidesPerView: 3.5, spaceBetween: 10 },
                    500: { slidesPerView: 4.5, spaceBetween: 30 },
                    800: { slidesPerView: 5.5 },
                    1020: { slidesPerView: 7 }
                }"
                :navigation="{
                    nextEl: '.next-swiper-category',
                    prevEl: '.prev-swiper-category'
                }"
                :class="initSwiper ? '' : 'grid grid-cols-7'"
                class="relative"
            >
                <SwiperSlide v-for="(item, index) in data" :key="index">
                    <nuxt-link :to="item?.url" class="col-flex gap-6 text-blacks-100 group overflow-hidden p-2">
                        <NuxtImg
                            :src="getThumbnail(item?.icon, 200, true)"
                            width="200"
                            sizes="sm:100vw md:50vw lg:200px "
                            loading="lazy"
                            :alt="'Icon ' + item.title"
                            :placeholder="[50, 25, 75, 5]"
                            class="w-12 h-12 xl:w-16 xl:h-16 object-contain group-hover:scale-110 duration-200"
                        />

                        <p class="text-15px xl:text-lg group-hover:text-blacks-100/70 text-center">{{ item?.title }}</p>
                    </nuxt-link>
                </SwiperSlide>
            </Swiper>

            <div
                v-if="data && data.length > 7"
                class="absolute z-100 swiper-navigation-collection flex items-center justify-between w-full"
            >
                <button
                    class="prev-swiper-category w-8 h-8 lg:w-12 lg:h-12 text-white flex flex-col items-center justify-center bg-blacks-100/60 hover:bg-blacks-100 duration-300 transform -translate-x-15"
                >
                    <span class="i-ic:sharp-keyboard-arrow-left text-2xl"></span>
                </button>
                <button
                    class="next-swiper-category w-8 h-8 lg:w-12 lg:h-12 text-white flex flex-col items-center justify-center bg-blacks-100/60 hover:bg-blacks-100 duration-300 translate-x-10"
                >
                    <span class="i-ic:sharp-keyboard-arrow-right text-2xl"></span>
                </button>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
const props = withDefaults(
    defineProps<{
        data: Array<any>
    }>(),
    {
        data: () => []
    }
)
const initSwiper = ref(false)
const getSwiper = (swiper) => {
    initSwiper.value = swiper.initialized
}
</script>

<style>
.prev-swiper-category.swiper-button-disabled,
.next-swiper-category.swiper-button-disabled {
    @apply !invisible opacity-0;
}
[v-cloak] {
    display: none;
}
</style>
