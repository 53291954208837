<template>
    <SlideBanner :listBanner="data?.banner?.banners" />

    <SectionCategory :data="data?.categories" />
    <SectionBlackFriday />
    <SectionCollection />
    <SectionListCollection :data="data?.featuredCollections" />

    <SectionVideo />
    <SectionRelatedProduct :data="data?.trendingProducts" />
    <SectionSupport />
    <SectionNews :data="data?.recentNews" />
</template>

<script setup lang="ts">
import SectionCategory from './SectionCategory.vue'
import SectionBlackFriday from './SectionBlackFriday.vue'
import SlideBanner from './components/SlideBanner.vue'
import SectionCollection from './SectionCollection.vue'
import SectionListCollection from './SecitonListConllection.vue'
import SectionVideo from './SectionVideo.vue'
import SectionRelatedProduct from './SectionRelatedProduct.vue'
import SectionSupport from './SectionSupport.vue'
import SectionNews from './SectionNews.vue'
import { useHasEvent } from '../../stores/useEventHeader'
const storeHasEvent = useHasEvent()
const hasEvent = ref(true)
const globalSettings = inject('globalSettings')

const { getBanner } = useBanner()
const { getRecentNews } = useNews()
const { getProductCategories, getTrendingProducts, getFeaturedCollections } = useProducts(globalSettings)
const { data, pending, refresh } = useAsyncData('homepage', async () => {
    const [banner, categories, trendingProducts, recentNews, featuredCollections] = await Promise.all([
        getBanner({ name: 'Homepage' }),
        getProductCategories(),
        getTrendingProducts({ query: { limit: 3 } }),
        getRecentNews({ query: { limit: 3 } }),
        getFeaturedCollections({ query: { limit: 4 } })
    ])
    return {
        banner,
        categories,
        trendingProducts,
        recentNews,
        featuredCollections
    }
})

onMounted(() => {
    storeHasEvent.hasEvent = hasEvent.value
})
useHead({
    meta: [
        {
            name: 'description',
            content:
                'Sản phẩm được phát triển tại CANADA, không chỉ chú trọng vào thiết kế đẹp mắt và tiện nghi, chúng tôi còn coi trọng việc thân thiện với môi trường.'
        },
        {
            name: 'robots',
            content: 'index, follow'
        }
    ]
})
useDirectusCollectionSeo('home', {})
</script>

<style scoped></style>
