<template>
    <div class="w-full h-full col-flex relative">
        <Swiper
            :modules="[SwiperAutoplay, SwiperPagination]"
            :slides-per-view="1"
            :space-between="0"
            :pagination="{
                el: '.slide-pagination-home',
                clickable: true
            }"
            :speed="500"
            :grabCursor="true"
            class="w-full h-full"
            v-if="listBanner?.banner_items && listBanner?.banner_items.length > 0"
        >
            <SwiperSlide v-for="item in listBanner?.banner_items" class="relative swiper-slide w-full !h-auto col-flex">
                <div class="w-full h-full absolute inset-0">
                    <NuxtImg
                        :src="getThumbnail(item?.image_bg, getWidth(), true)"
                        sizes="sm:100vw md:50vw lg:2000px "
                        loading="lazy"
                        alt="Banner Home"
                        :placeholder="[50, 25, 75, 5]"
                        class="w-full h-full object-cover"
                    />
                </div>
                <div class="absolute inset-0 z-2 bg-overlay-01"></div>
                <div class="container relative z-10 py-23 lg:py-30 h-full col-flex">
                    <div class="w-full max-w-262 text-center">
                        <div
                            v-if="item?.caption_description"
                            v-html="item?.caption_description"
                            ref="js_description_slide_home"
                            class="heading_collection"
                        ></div>
                        <div v-else>
                            <div
                                v-html="item?.caption_title"
                                ref="js_heading_slide_home"
                                class="heading-slide-home text-4xl lg:text-64px text-white font-bold leading-12 lg:leading-77px"
                            ></div>
                            <div
                                v-html="item?.caption_subtitle"
                                ref="js_sub_heading_slide_home"
                                class="sub-heading-slide-home text-base lg:text-lg text-white mt-2"
                            ></div>
                        </div>
                        <div
                            v-if="item.btn_link || item.btn_text"
                            data-aos="fade-up"
                            data-aos-once="true"
                            data-aos-delay="2000"
                        >
                            <nuxt-link
                                :to="item?.btn_link"
                                class="slide text-blacks-100 text-lg lg:text-base font-medium bg-white py-5 px-12 inline-block mt-12"
                            >
                                <p v-if="item?.btn_text">{{ item?.btn_text }}</p>
                                <p v-else>Khám phá ngay</p>
                            </nuxt-link>
                        </div>
                    </div>
                </div>
            </SwiperSlide>
        </Swiper>

        <div class="container absolute bottom-[5%] z-10 w-full">
            <div class="slide-pagination-home"></div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useWindowSize } from '@vueuse/core'

defineProps({
    listBanner: Object as any
})
const { width, height } = useWindowSize()
const getWidth = () => {
    if (width.value < 640) {
        return 500
    } else if (width.value < 768) {
        return 700
    } else {
        return 2000
    }
}
const js_heading_slide_home = ref<HTMLElement>([])
const js_sub_heading_slide_home = ref<HTMLElement>([])
const js_description_slide_home = ref<HTMLElement>([])

onMounted(() => {
    const wordsHeading = js_heading_slide_home.value[0].innerText.split(' ')
    const wordsSubHeading = js_sub_heading_slide_home.value[0].innerText.split(' ')
    js_heading_slide_home.value[0].innerText = ''
    js_sub_heading_slide_home.value[0].innerText = ''
    document.querySelectorAll('.heading-slide-home').forEach((item: any) => item.classList.add('active'))
    document.querySelectorAll('.sub-heading-slide-home').forEach((item: any) => item.classList.add('active'))
    wordsHeading.forEach(function (word) {
        const span = document.createElement('span')
        span.innerText = word + ' '
        const spans = document.querySelectorAll('.heading-slide-home span')
        js_heading_slide_home.value[0].appendChild(span)
        document.querySelectorAll('.heading-slide-home span').forEach(function (span, index) {
            span.style.animation = `fade-in-heading 0.8s ${0.1 * index}s forwards cubic-bezier(0.11, 0, 0.5, 0)`
        })
    })
    wordsSubHeading.forEach(function (word) {
        const span = document.createElement('span')
        span.innerText = word + ' '
        const spans = document.querySelectorAll('.sub-heading-slide-home span')
        js_sub_heading_slide_home.value[0].appendChild(span)
        document.querySelectorAll('.sub-heading-slide-home span').forEach(function (span, index) {
            span.style.animation = `fade-in-heading 2s ${0.1 * index}s forwards cubic-bezier(0.11, 0, 0.5, 0)`
        })
    })
    if (js_description_slide_home.value && js_description_slide_home.value.length > 0) {
        const wordsDescription = js_description_slide_home.value[0].innerHTML
        js_description_slide_home.value[0].innerHTML = ''
        document.querySelectorAll('.heading_collection').forEach((item) => item.classList.add('active'))
        wordsDescription.split(/(<\>)/).forEach(function (word) {
            const div = document.createElement('div')
            // console.log('🚀 ~ file: SectionBanner.vue:91 ~ div:', div)
            div.innerHTML = word + ' '
            js_description_slide_home.value[0].appendChild(div)
            js_description_slide_home.value[0].querySelectorAll('div').forEach(function (div, index) {
                div.querySelectorAll('p , li').forEach(function (content, index) {
                    content.textContent.split(' ').forEach((word: string) => {
                        const span = document.createElement('span')
                        span.innerText = word + ' '
                    })
                    // content.parentNode.replaceChild(blockAnimation, content)
                })
                div.classList.add('inline', 'leading-40px', 'sub-heading-collection-custom')

                div.style.animation = `fade-in-heading 1s ${0.1 * index}s forwards cubic-bezier(0.11, 0, 0.5, 0)`
            })
        })
    }
})
</script>

<style></style>
