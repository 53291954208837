<template>
    <section>
        <div v-if="is_edit" class="container pt-5">
            <p class="font-semibold">Link video:</p>
            <v-text option_key="home_link_video" class="text-sm text-blacks-70"></v-text>
        </div>
        <div class="relative my-12 md:my-20 xl:my-24">
            <div class="absolute inset-0 z-10">
                <v-image
                    option_key="home_thumbnail_video"
                    :width="getWidth()"
                    class="w-full h-full object-cover"
                ></v-image>
            </div>
            <div :class="isOpenVideo ? 'z-100' : 'z-1'" class="absolute inset-0">
                <div id="player" class="w-full h-full aspect-16/9"></div>
            </div>

            <div class="container h-full w-full flex items-center py-20 md:py-40 xl:py-47 relative z-10">
                <div class="w-full max-w-9/10">
                    <div class="w-full flex flex-col lg:flex-row lg:items-center justify-between gap-10">
                        <div class="order-2 lg:order-1 max-w-148 w-full">
                            <v-content
                                class="text-36px lg:text-48px text-white font-medium sm:leading-12 lg:leading-16"
                                option_key="home_title_video"
                            ></v-content>

                            <v-content
                                class="text-base md:text-lg text-white font-medium mt-4 sm:mt-8"
                                option_key="home_subTitle_video"
                            >
                            </v-content>
                        </div>

                        <div
                            @click="isOpenVideo = true"
                            class="order-1 lg:order-2 flex-none btn-effect-light w-22.5 h-22.5 md:w-33 md:h-33 col-flex rounded-full cursor-pointer"
                        >
                            <img
                                src="/images/home/collection/play-video.svg"
                                alt="icon-play-video"
                                class="w-full h-full object-contain"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
import { useWindowSize } from '@vueuse/core'

const is_edit = inject('is_edit')
const link_video = ref(getDataGlobalByKey('vi-VN', 'TEXT__home_link_video')?.option_value)
const isOpenVideo = ref(false)
const extractYouTubeVideoID = (url) => {
    const regExp = /^.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/
    const match = url.match(regExp)
    return match && match[1] ? match[1] : null
}
watch(
    () => isOpenVideo.value,
    (newValue) => {
        if (newValue) {
            onYouTubeIframeAPIReady()
        }
    }
)
const videoID = extractYouTubeVideoID(link_video.value)

// s
const { width, height } = useWindowSize()
const getWidth = () => {
    if (width.value < 640) {
        return 500
    } else if (width.value < 768) {
        return 700
    } else {
        return 2000
    }
}
var playerMain
function onYouTubeIframeAPIReady() {
    playerMain = new YT.Player('player', {
        videoId: videoID,
        playerVars: {
            playsinline: 1,
            playlist: videoID,
            loop: true,
            autoplay: 1,
            rel: 0,
            showinfo: 0,
            ecver: 2
        },

        events: {
            onReady: onPlayerReady,
            onStateChange: onPlayerStateChange
        }
    })
}
function onPlayerReady(event) {
    event.target.playVideo()
}

function onPlayerStateChange(event) {
    if (event.data !== 2) {
        isOpenVideo.value = true
    } else {
        isOpenVideo.value = false
    }
}

onMounted(() => {
    var tag = document.createElement('script')
    tag.src = 'https://www.youtube.com/iframe_api'
    var firstScriptTag = document.getElementsByTagName('script')[1]
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
})
</script>

<style></style>
