<template>
    <section class="bg-[#F8F8F8] py-10 md:py-24">
        <div class="container">
            <div class="text-center mx-auto">
                <v-content
                    tag="h2"
                    class="text-36px lg:text-48px sm:leading-12 lg:leading-16 text-blacks-100 font-medium"
                    option_key="home_title_support"
                >
                </v-content>
                <v-content tag="h4" option_key="home_subTitle_support" class="text-lg text-blacks-50 mt-1"> </v-content>
            </div>
            <div
                class="flex flex-col lg:flex-row items-center justify-between md:justify-around mt-8 md:mt-16 gap-6 w-full"
            >
                <v-link v-for="(item, index) in 4" :option_key="'home_support_item_link_' + index">
                    <div class="flex-none flex col-flex gap-3 lg:gap-6 group py-2 min-w-41">
                        <div class="w-16 h-16">
                            <v-image
                                alt="icon"
                                :option_key="'home_support_item_icon_' + index"
                                class="w-full h-full group-hover:scale-120 duration-300"
                            />
                        </div>
                        <div class="w-full text-center">
                            <v-content
                                :icons="[]"
                                :option_key="'home_support_item_title_' + index"
                                class="text-[#333] text-lg group-hover:text-blacks-100"
                            ></v-content>
                        </div>
                    </div>
                </v-link>
            </div>
            <div class="flex flex-col lg:flex-row items-center gap-10 sm:gap-15 ld:gap-26 mt-12 lg:mt-24">
                <div class="w-full lg:w-1/2">
                    <div class="aspect-4/3">
                        <v-image
                            option_key="home_support_image"
                            :width="getWidth()"
                            alt=""
                            class="w-full h-full object-cover"
                        />
                    </div>
                </div>
                <div class="w-full lg:w-1/2">
                    <v-content
                        option_key="home_showroom_title"
                        class="text-36px lg:text-48px sm:leading-12 lg:leading-16 text-blacks-100 font-medium"
                    >
                    </v-content>
                    <v-content option_key="home_showroom_subTitle" class="text-blacks-50 text-lg font-medium mt-4">
                    </v-content>
                    <v-link option_key="home_showroom_link">
                        <div
                            class="btn-effect-dark col-flex lg:inline-flex py-5 px-12 text-white text-lg font-medium bg-blacks-100 mt-5 lg:mt-12"
                        >
                            <v-text :icons="[]" option_key="home_showroom_text_link"></v-text>
                        </div>
                    </v-link>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
import { useWindowSize } from '@vueuse/core'

const { width, height } = useWindowSize()
const getWidth = () => {
    if (width.value < 640) {
        return 400
    } else if (width.value < 768) {
        return 500
    } else {
        return 800
    }
}
</script>

<style></style>
