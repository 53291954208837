<template>
    <section class="relative">
        <div class="absolute bottom-0 right-0 z-1">
            <img
                style="filter: brightness(104%)"
                src="/images/home/collection/background-pattern.png"
                alt="bg-patter"
                class="w-full h-full object-cover bg-filter-brightnes"
            />
        </div>
        <div class="relative z-10 container my-15 lg:my-24">
            <div class="flex flex-col lg:flex-row items-center gap-10 lg:gap-30">
                <div class="w-full lg:w-4/10">
                    <v-content class="" option_key="home_collection_title"></v-content>
                    <!-- <v-content
                        option_key="home_collection_title"
                       
                    ></v-content> -->
                    <v-link option_key="home_collection_btn" class="inline-block">
                        <div
                            class="btn-arrow btn-effect-dark w-full md:w-max flex items-center justify-center md:justify-start gap-3 text-white bg-blacks-100 py-5 px-12 mt-5 lg:mt-10"
                        >
                            <v-text :icons="[]" option_key="home_text_btn_collection"></v-text>
                            <i class="arrow-icon i-ic:baseline-arrow-outward text-lg"></i>
                        </div>
                    </v-link>

                    <div class="relative mt-10 w-full h-60 sm:h-85">
                        <div
                            class="absolute top-0 bottom-0 w-full lg:w-auto right-0 md:right-40 lg:right-0 aspect-16/9 group overflow-hidden max-h-55 sm:max-h-full min-h-55 sm:min-h-full"
                        >
                            <v-image
                                class="w-full h-full object-cover transform duration-500 group-hover:scale-111"
                                :width="getWidth()"
                                option_key="home_collection_thumbnail1"
                            ></v-image>
                        </div>
                        <div
                            class="absolute top-full md:-top-5 right-0 md:right-20 lg:-right-20 transform -translate-y-14 z-10"
                        >
                            <v-image
                                class="w-40 h-40 object-contain"
                                :width="getWidth2()"
                                option_key="home_collection_stamp"
                            ></v-image>
                        </div>
                    </div>
                </div>

                <div class="w-full lg:w-6/10 h-full flex flex-col gap-5">
                    <div class="aspect-193/132 group overflow-hidden">
                        <v-image
                            class="w-full h-full object-cover duration-500 group-hover:scale-110"
                            :width="getWidth()"
                            option_key="home_collection_thumbnail2"
                        ></v-image>
                    </div>
                    <div class="text-lg text-black lg:mt-12">
                        <v-content option_key="home_collection_description" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
import { useWindowSize } from '@vueuse/core'

const { width, height } = useWindowSize()
const getWidth = () => {
    if (width.value < 640) {
        return 400
    } else if (width.value < 768) {
        return 500
    } else {
        return 800
    }
}
const getWidth2 = () => {
    if (width.value < 640) {
        return 200
    } else if (width.value < 768) {
        return 300
    } else {
        return 400
    }
}
</script>

<style></style>
